import { isReference } from "@apollo/client";
import {
  CanReadFunction,
  ReadFieldFunction,
  ToReferenceFunction,
} from "@apollo/client/cache/core/types/common";
import { StorageType } from "@apollo/client/cache/inmemory/policies";
import { AppContextState } from "src/AppContextProvider";
import LoadMoreButton from "src/components/LoadMoreButton";
import { DEFAULT_PAGE_LIMIT } from "src/constants/constants";

export const renderFetchMoreButton = (
  currentItemsLength: number,
  totalCount: number,
  isLoading: boolean,
  hasNextPage: boolean,
  callBack: () => void
) => {
  const itemsLeftCount = totalCount - currentItemsLength;
  return hasNextPage ? (
    <LoadMoreButton
      isLoading={isLoading}
      itemCount={itemsLeftCount}
      onClick={callBack}
    />
  ) : null;
};

export const renderFetchMorePickerButton = (
  currentItemsLength: number,
  totalCount: number,
  isLoading: boolean,
  hasNextPage: boolean,
  callBack: () => void
) => {
  const itemsLeftCount = totalCount - currentItemsLength;
  return hasNextPage ? (
    <div className="loadmore-items">
      <LoadMoreButton
        isLoading={isLoading}
        itemCount={itemsLeftCount}
        onClick={callBack}
      />
    </div>
  ) : null;
};

export const truncateStringInMiddle = (str: string, maxLength: number) => {
  const NUM_CHARS = maxLength;
  const ellipsisDivs = document.querySelectorAll(".ellipsis");
  if (str.length > NUM_CHARS) {
    const firstPart = str.substr(0, NUM_CHARS / 2);
    const secondPart = str.substr(str.length - NUM_CHARS / 2);
    const newStr = firstPart + "..." + secondPart;
    ellipsisDivs.forEach((div) => {
      div.innerHTML = newStr;
    });

    return newStr;
  }
  return str;
};

// * NOTE: since there is no exported type so let's copy this out from https://github.com/apollographql/apollo-client/blob/v3.5.4/src/cache/core/types/common.ts#L61
interface ApolloModifierDetails {
  DELETE: any;
  INVALIDATE: any;
  fieldName: string;
  storeFieldName: string;
  readField: ReadFieldFunction;
  canRead: CanReadFunction;
  isReference: typeof isReference;
  toReference: ToReferenceFunction;
  storage: StorageType;
}

export const isNotRelatedCacheToModify = (
  context: AppContextState,
  details: ApolloModifierDetails
) => {
  const notEqualToCurrentSpaceId = `"notEqualTo":"${context.currentSpace?.id}"`;
  const containsNotEqualToQuery = details.storeFieldName.includes(
    notEqualToCurrentSpaceId
  );

  return containsNotEqualToQuery;
};

export const getTotalPages = ({
  totalSearchItemsCount,
  totalItemsCount,
  limit,
}: {
  totalSearchItemsCount?: number;
  totalItemsCount: number;
  limit?: number;
}) => {
  if (totalSearchItemsCount) {
    return Math.ceil(totalSearchItemsCount / (limit || DEFAULT_PAGE_LIMIT));
  } else {
    return Math.ceil(totalItemsCount / (limit || DEFAULT_PAGE_LIMIT));
  }
};

export const getTotalCount = ({
  totalSearchItemsCount,
  totalItemsCount,
}: {
  totalSearchItemsCount: number;
  totalItemsCount: number;
}) => (totalSearchItemsCount > 0 ? totalSearchItemsCount : totalItemsCount);
