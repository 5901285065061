import { Maybe, User } from "src/types.g";
import Intercom from "@intercom/messenger-js-sdk";

export const initIntercom = (user: Maybe<User>) => {
  if (!user) {
    return;
  }

  const appId = "zo5byv8u";
  const userId = user.id;
  const name = `${user.givenName} ${user.familyName}`;
  const email = user.email ?? "unknown";
  const createdAt = user.createdAt;

  Intercom({
    app_id: appId,
    user_id: userId,
    name,
    email,
    created_at: createdAt,
  });
};
